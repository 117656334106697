<template>
  <v-card
    class="mx-n3 mx-sm-16 px-0 px-sm-4 px-md-8 flex-fill"
    max-width="420"
    flat
    tile
    outlined
  >
    <v-card-title class="my-3 my-sm-6 my-md-12 text-h4 font-weight-bold justify-center">
      {{ gc_langText.login.title[gc_lang] }}
    </v-card-title>

    <v-card-text class="py-0">
      <!--  form 박스  -->
      <v-form
        ref="form"
        v-model="formValid"
      >
        <!--  이메일  -->
        <v-text-field
          :label="gc_langText.common.account.id[gc_lang]"
          class="rounded-0"
          v-model="form.mail"
          :rules="[gc_rules.required, gc_rules.mail]"
          placeholder="hello@vuno.co"
          dense
          outlined
          validate-on-blur
          :disabled="requesting"
          autofocus
        />

        <!--  비밀번호  -->
        <v-text-field
          :label="gc_langText.common.account.pw[gc_lang]"
          class="rounded-0"
          v-model="form.pw"
          :rules="[gc_rules.required]"
          @keypress.enter="gm_submitEnter"
          type="password"
          dense
          outlined
          validate-on-blur
          :disabled="requesting"
        />

      </v-form>
    </v-card-text>

    <v-card-actions class="px-4 pt-0 flex-column">

      <v-btn
        ref="submit"
        class="text-h6"
        @click="submit"
        elevation="0"
        color="cyan"
        :dark="!requesting"
        :disabled="requesting"
        :loading="requesting"
        block
        large
        tile
      >
        {{ gc_langText.login.enterBtn[gc_lang] }}
      </v-btn>

      <v-btn
        class="mt-6 ml-0 text-h6"
        elevation="0"
        to="/join"
        block
        large
        outlined
        tile
      >
        {{ gc_langText.login.joinBtn[gc_lang] }}
      </v-btn>

      <v-btn
        class="mt-6 ml-0 text-h6"
        elevation="0"
        to="/"
        block
        large
        outlined
        tile
      >
        {{ gc_langText.common.etc.goToHomeBtn[gc_lang] }}
      </v-btn>

      <v-row class="my-6">
        <v-col class="pa-0">
          <v-btn
            class="c-divider"
            to="/inquiry/id"
            plain
          >
            {{ gc_langText.login.findIdBtn[gc_lang] }}
          </v-btn>
          <v-btn
            to="/inquiry/pw"
            plain
          >
            {{ gc_langText.login.findPwBtn[gc_lang] }}
          </v-btn>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'Login',

  data: () => ({
    // 폼 입력 데이터
    form: {
      mail: '',
      pw: ''
    },

    // 폼 내부 입력 값들 유효 상태
    formValid: true,

    // 요청 상태
    requesting: false
  }),

  methods: {
    // 폼 입력값 전송
    submit() {
      this.$refs.form.validate()

      if (!this.formValid) { return }

      const params = {
        email: this.form.mail,
        password: this.form.pw
      }

      this.requesting = true

      this.api_signIn(params)
    }
  }
}
</script>

<style lang="sass" scoped>
</style>